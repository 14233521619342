import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/SEO"

const BoilersPage = () => {
  const data = useStaticQuery(graphql`
    query {
      main: file(relativePath: { eq: "images/boilers/hero.jpg" }) {
        ...heroImage
      }
      boilers: file(
        relativePath: { eq: "images/boilers/services/boilers.jpg" }
      ) {
        ...columnImage
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Boilers - Uytterhaegen Jan BV" pathname="/boilers" />
      <BackgroundImage
        Tag="section"
        className="main-hero"
        fluid={data.main.childImageSharp.fluid}
        backgroundColor={`#040e18`}
      >
        <div className="main-hero-content">
          <div className="main-hero-content-title">
            <h2>Boilers</h2>
          </div>
        </div>
      </BackgroundImage>
      <div className="container">
        <div className="wrapper">
          <div className="content">
            <section>
              <div className="two-column">
                <div className="column-60">
                  <h1>Boilers</h1>
                  <p>
                    Mogelijkheden om uw water op de juiste temperatuur te
                    brengen :
                  </p>
                  <ul>
                    <li>
                      <strong>Doorstromers:</strong> wanneer er warmwater
                      gevraagd wordt zal het toestel uw water ogenblikkelijk
                      beginnen warmen
                    </li>
                    <li>
                      <strong>Gasboiler:</strong> een voorraadvat met warm water
                      dat opgewarmd wordt met een gasbrander
                    </li>
                    <li>
                      <strong>Elektrische boiler:</strong> Voorraadvat dat water
                      verwarmt met een elektrische weerstand
                    </li>
                    <li>
                      <strong>Boiler op centrale verwarming:</strong> het water
                      wordt opgewarmd door een warmtespiraal in de boiler dat
                      opgewarmd wordt door de centrale verwarming
                    </li>
                    <li>
                      <strong>Zonneboiler:</strong> Zonnepanelen geven warmte af
                      aan boiler om het water op te warmen, wordt ook
                      gecombineerd met een warmtespiraal of een elektrische
                      weerstang. Hiervoor zijn nog steeds premies te krijgen.
                      Kijk hiervoor op www.energiesparen.be
                    </li>
                    <li>
                      <strong>Warmtepomp boiler:</strong> De boiler gaat
                      gebruikmaken van de omgevingslucht om het water op te
                      warmen, kan ook gecombineerd worden met warmtespiraal
                      en/of elektrische weerstand
                    </li>
                  </ul>
                  <p>
                    Wil je meer info omtrent deze producten, aarzel niet ons te
                    contacteren en wij helpen je graag verder
                  </p>
                </div>
                <div className="column-40">
                  <Img
                    alt={data.boilers.childImageSharp.name}
                    fluid={data.boilers.childImageSharp.fluid}
                  />
                </div>
              </div>
            </section>
            {/* <section>
              <h1>Enkele van onze realisaties</h1>
              <Lightbox
                thumbs={data.thumbs.edges}
                images={data.realisaties.edges}
              />
            </section> */}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BoilersPage
